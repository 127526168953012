import { Flex } from "@100mslive/roomkit-react";
import { Audio } from "react-loader-spinner";

const FullPageProgress = () => (
  <Flex justify="center" align="center" css={{ size: "100%" }}>
    <Audio
      height="100"
      width="100"
      color="#9e1f63"
      ariaLabel="audio-loading"
      wrapperStyle={{}}
      wrapperClass="wrapper-class"
      visible={true}
    />
  </Flex>
);

export default FullPageProgress;
