import React, { useCallback, useContext, useEffect, useRef } from "react";
import { Fingerprint } from "../Fingerprint/fingerprint";
import { Flex, IconButton, styled } from "@100mslive/roomkit-react";
import {
  useHMSActions,
  useHMSStore,
  selectLocalPeer,
  selectSessionId,
  selectRoomID,
} from "@100mslive/react-sdk";
import { ToastManager } from "../Toast/ToastManager";
import { SendIcon } from "@100mslive/react-icons";
import { useChatDraftMessage } from "../AppData/useChatState";
import { useParams } from "react-router-dom";
import { hotjar_event, postChats } from "../../common/utils";

const TextArea = styled("textarea", {
  width: "100%",
  bg: "transparent",
  color: "#9e1f63",
  resize: "none",
  lineHeight: "1rem",
  "&:focus": {
    boxShadow: "none",
    outline: "none",
  },
});

export const ChatFooter = ({ role, peerId, onSend, children }: any) => {
  const hmsActions = useHMSActions();
  const sessionId = useHMSStore(selectSessionId);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [draftMessage, setDraftMessage] = useChatDraftMessage();
  const sender = useHMSStore(selectLocalPeer);
  const room = useHMSStore(selectRoomID);
  const senderFp =
    useContext(Fingerprint) || "abcdef1234567890abcdef1234567890";
  let msgType = "";
  const sendMessage = useCallback(async () => {
    const message = inputRef.current?.value;
    if (!message || !message.trim().length) {
      return;
    }
    try {
      if (role) {
        await hmsActions.sendGroupMessage(message, [role]);
        msgType = "group " + role;
      } else if (peerId) {
        await hmsActions.sendDirectMessage(message, peerId);
        msgType = "direct to " + peerId;
      } else {
        await hmsActions.sendBroadcastMessage(message);
        msgType = "broadcast";
      }
      inputRef.current.value = "";
      if (!message.startsWith("data:image")) {
        hotjar_event("chat");
      }
      setTimeout(() => {
        const msg = {
          fingerprint: senderFp,
          content: message,
          sender_name: sender?.name || "",
          sender_id: sender?.id || "",
          popup_uuid: room,
          metadata: msgType,
          avatar: "bee",
          thread_uuid: sessionId,
        };
        postChats(msg);
        onSend();
      }, 0);
    } catch (error: any) {
      ToastManager.addToast({ title: error.message });
    }
  }, [role, peerId, hmsActions, onSend]);

  useEffect(() => {
    const messageElement = inputRef.current;
    if (messageElement) {
      messageElement.value = draftMessage;
    }
  }, [draftMessage]);

  useEffect(() => {
    const messageElement = inputRef.current;
    return () => {
      setDraftMessage(messageElement?.value || "");
    };
  }, [setDraftMessage]);

  return (
    <Flex
      align="center"
      css={{
        borderTop: "1px solid $borderDefault",
        borderBottomLeftRadius: "$2",
        borderBottomRightRadius: "$2",
        bg: "rgba(48, 55, 64, 0.95)",
        position: "relative",
        pl: "$8",
        pt: "$2",

        fontSize: "12px",
      }}
    >
      {children}
      <TextArea
        css={{
          lineHeight: "0.75rem",
        }}
        placeholder="Share your thoughts..."
        ref={inputRef}
        id="chat-input"
        autoFocus
        onKeyPress={async event => {
          if (event.key === "Enter") {
            if (!event.shiftKey) {
              event.preventDefault();
              await sendMessage();
            }
          }
        }}
      />
      <IconButton
        onClick={sendMessage}
        id="send-btn"
        css={{ ml: "auto", height: "max-content", mr: "$4" }}
      >
        <SendIcon />
      </IconButton>
    </Flex>
  );
};
