import { useState, useEffect, useCallback } from "react";
import {
  usePreviewJoin,
  selectLocalPeer,
  useHMSStore,
  selectIsLocalVideoEnabled,
  useAVToggle,
  useDevices,
  useHMSActions,
} from "@100mslive/react-sdk";
import { Audio } from "react-loader-spinner";
import {
  StyledVideoTile,
  Video,
  Input,
  Flex,
  Avatar,
  styled,
} from "@100mslive/roomkit-react";
import {
  Button,
  Text,
  IconButton,
  useBorderAudioLevel,
  useTheme,
} from "../react-ui/src";
import { SettingsIcon } from "@100mslive/react-icons";
import { AudioVideoToggle } from "./AudioVideoToggle";
import ig from "../images/ig_logo.svg";
import twitter from "../images/twitter_logo.svg";
import tiktok from "../images/tt_logo.svg";

import Settings from "./Settings";
import {
  UserPreferencesKeys,
  useUserPreferences,
} from "./hooks/useUserPreferences";

const defaultPreviewPreference = {
  name: "",
  isAudioMuted: false,
  isVideoMuted: false,
};

const Preview = ({
  token,
  onJoin,
  env,
  skipPreview,
  initialName,
  roomName,
}: any) => {
  const [previewPreference, setPreviewPreference] = useUserPreferences(
    UserPreferencesKeys.PREVIEW,
    defaultPreviewPreference
  );
  const [name, setName] = useState(initialName || previewPreference.name);
  const { allDevices } = useDevices();
  const { videoInput } = allDevices;
  const hmsActions = useHMSActions();
  let deviceIds = videoInput?.filter(
    info => info?.label?.includes("back") || info?.label?.includes("Back")
  );
  if (!deviceIds || deviceIds.length === 0) {
    deviceIds = videoInput;
  }
  const defaultCamera =
    deviceIds?.length && videoInput?.length
      ? deviceIds[0]?.deviceId || videoInput[0]?.deviceId
      : typeof videoInput === "string"
      ? videoInput
      : "";
  const { isLocalAudioEnabled, isLocalVideoEnabled } = useAVToggle();
  const { enableJoin, preview, join } = usePreviewJoin({
    name,
    token,
    initEndpoint: env ? `https://${env}-init.100ms.live/init` : undefined,
    initialSettings: {
      isAudioMuted: skipPreview ? true : previewPreference.isAudioMuted,
      isVideoMuted: skipPreview ? true : previewPreference.isVideoMuted,
      videoDeviceId: defaultCamera,
    },
    captureNetworkQualityInPreview: true,
  });

  const savePreferenceAndJoin = useCallback(() => {
    window.localStorage.setItem("username", name);
    setPreviewPreference({
      name,
      isAudioMuted: !isLocalAudioEnabled,
      isVideoMuted: !isLocalVideoEnabled,
    });
    join();
    onJoin && onJoin();
  }, [
    setPreviewPreference,
    name,
    isLocalAudioEnabled,
    isLocalVideoEnabled,
    join,
    onJoin,
  ]);

  useEffect(() => {
    if (token) {
      if (skipPreview) {
        savePreferenceAndJoin();
      } else if (defaultCamera) {
        hmsActions.setVideoSettings({ deviceId: defaultCamera });
      } else {
        preview();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    skipPreview,
    defaultCamera,
    isLocalAudioEnabled,
    isLocalVideoEnabled,
  ]);

  return (
    <Container>
      <PreviewTile name={name} />
      <Flex direction="column" align="center" css={{ mt: "1rem" }}>
        <Text css={{ color: "White", margin: "$1" }}>
          You are about to join <span color="">@{roomName}'s</span> video chat
          room.
        </Text>
        <Text css={{ color: "White", margin: "$1" }}>
          What name do you go by?
        </Text>
        <Text css={{ color: "White", margin: "$1" }}>
          <span>
            <img
              src={ig}
              alt="instagram"
              style={{ height: 20, display: "unset" }}
            />
          </span>
          {" /"}
          <span>
            <img
              src={twitter}
              alt="twitter"
              style={{ height: 20, display: "unset" }}
            />
          </span>
          {"/ "}
          <span>
            <img
              src={tiktok}
              alt="tiktok"
              style={{ height: 20, display: "unset" }}
            />
          </span>
        </Text>
        <Flex
          css={{ margin: "$1" }}
          as="form"
          direction="column"
          align="center"
          onSubmit={(e: any) => {
            e.preventDefault();
            savePreferenceAndJoin();
          }}
        >
          <Input
            css={{
              border: "unset",
              boxShadow: "none !important",
              backgroundColor: "White",
              color: "unset",
              margin: "$2",
            }}
            autoComplete="name"
            type="text"
            required
            autoFocus={true}
            maxLength={40}
            value={name}
            placeholder="Name_name"
            aria-placeholder="Name_name"
            onChange={e => {
              // overwrite target.value to remove double @ and spaces
              e.target.value =
                e.target.value.length === 1
                  ? "@" + e.target.value
                  : e.target.value;
              if (e.target.value === "@@") {
                e.target.value = "";
              }
              setName(e.target.value);
            }}
            data-testid="preview_name_field"
          />

          <Button
            css={{
              color: "Black",
              borderColor: "$borderLight",
              textTransform: "uppercase",
            }}
            type="submit"
            disabled={!name || !enableJoin}
            data-testid="preview_join_btn"
          >
            Join!
          </Button>
        </Flex>
      </Flex>
    </Container>
  );
};

const PreviewTile = ({ name }: any) => {
  const localPeer = useHMSStore(selectLocalPeer);
  const borderAudioRef = useBorderAudioLevel(localPeer?.audioTrack);
  const isVideoOn = useHMSStore(selectIsLocalVideoEnabled);
  const {
    aspectRatio: { width, height },
  } = useTheme();
  return (
    <StyledVideoTile.Container
      css={{
        aspectRatio: width / height,
        width: "unset",
        height: "min(360px, 60vh)",
        "@sm": {
          height: "unset",
          width: "min(360px, 90%)",
          maxWidth: "90%",
        },
      }}
      ref={borderAudioRef}
    >
      {localPeer ? (
        <>
          <Video
            mirror={true}
            trackId={localPeer.videoTrack}
            data-testid="preview_tile"
          />
          {!isVideoOn ? (
            <Avatar name={name} data-testid="preview_avatar_tile" />
          ) : null}
          <StyledVideoTile.AttributeBox css={controlStyles}>
            <AudioVideoToggle compact />
          </StyledVideoTile.AttributeBox>
          {false && (
            <Settings>
              <StyledVideoTile.AttributeBox css={settingStyles}>
                <IconButton data-testid="preview_setting_btn">
                  <SettingsIcon />
                </IconButton>
              </StyledVideoTile.AttributeBox>
            </Settings>
          )}
        </>
      ) : (
        <Audio
          height="100"
          width="100"
          color="#9e1f63"
          ariaLabel="audio-loading"
          wrapperStyle={{}}
          wrapperClass="wrapper-class"
          visible={true}
        />
      )}
    </StyledVideoTile.Container>
  );
};

const Container = styled("div", {
  borderRadius: "$2",
  backgroundColor: "$previewBg",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "2rem 6rem",
  "@md": {
    borderRadius: "0",
    padding: "0",
    width: "100%",
    height: "100%",
  },
});

const controlStyles = {
  bottom: "10px",
  left: "50%",
  transform: "translate(-50%, 0)",
  display: "flex",
  "& > * + *": {
    marginRight: "0",
    marginLeft: "0.5rem",
  },
};

const settingStyles = {
  bottom: "10px",
  right: "20px",
};

export default Preview;
