import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import {
  HMSRoomProvider,
  selectLocalPeerID,
  useHMSStore,
} from "@100mslive/react-sdk";
import { Box, ThemeTypes } from "@100mslive/roomkit-react";
import { AppContextProvider } from "./components/context/AppContext";
import { Notifications } from "./components/Notifications";
import { Confetti } from "./plugins/confetti";
import { ToastContainer } from "./components/Toast/ToastContainer";
import { FeatureFlags } from "./services/FeatureFlags";
import { FingerprintProvider } from "./components/Fingerprint/fingerprint";
import {
  getUserToken as defaultGetUserToken,
  getBackendEndpoint,
} from "./services/tokenService";
import getVanityUrls from "./services/vanityUrlService";
import "./base.css";
import "./index.css";
// @ts-ignore
import Logo from "./images/stumble_1110_408.png";
import FullPageProgress from "./components/FullPageProgress";
import { KeyboardHandler } from "./components/Input/KeyboardInputManager";
import PostLeave from "./components/PostLeave";
import { AppData } from "./components/AppData/AppData";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { HMSThemeProvider } from "./react-ui/src";
import { AppRoutesProps } from "./@types";
import HLSView from "./layouts/HLSView";

const Conference = React.lazy(() => import("./components/conference"));
const PreviewScreen = React.lazy(() => import("./components/PreviewScreen"));
const ErrorPage = React.lazy(() => import("./components/ErrorPage"));

const defaultTokenEndpoint = process.env
  .REACT_APP_TOKEN_GENERATION_ENDPOINT_DOMAIN
  ? `${getBackendEndpoint()}${
      process.env.REACT_APP_TOKEN_GENERATION_ENDPOINT_DOMAIN
    }/`
  : process.env.REACT_APP_TOKEN_GENERATION_ENDPOINT || "";

const envPolicyConfig = JSON.parse(process.env.REACT_APP_POLICY_CONFIG || "{}");
const ngrok_rooms = {
  alex: { roomId: "6408c0d85effce434ac7d6f3" },
  miketerry: { roomId: "65e559b40c0a1f5c351b30c6" },
  localcreators: { roomId: "65b97ea0517d71d91b155146" },
  love: { roomId: "66575994e85e6768d0e3fb29" },
};

let roomName = "";
if (window.location.host.includes("localhost")) {
  roomName = window.location.pathname.split("/")[1].split("-")[0] || "Mike";
} else {
  roomName = window.location.pathname.split("/")[1].split("-")[0] || "Mike";
}

roomName =
  roomName === "leave"
    ? window.location.pathname.split("/")[2] || "Mike"
    : roomName;

document.title = `${roomName}'s ${document.title}`;

const getAspectRatio = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) => {
  const host = process.env.REACT_APP_HOST_NAME || window.location.hostname;
  const portraitDomains = (
    process.env.REACT_APP_PORTRAIT_MODE_DOMAINS || ""
  ).split(",");
  if (portraitDomains.includes(host) && width > height) {
    return { width: height, height: width };
  }
  return { width, height };
};

export function EdtechComponent({
  roomId = "",
  tokenEndpoint = defaultTokenEndpoint,
  themeConfig: {
    aspectRatio = "1-1",
    font = "Roboto",
    color = "#9e1f63",
    theme = ThemeTypes.default,
    logo = "",
    headerPresent = "false",
    metadata = "",
  },
  getUserToken = defaultGetUserToken,
  policyConfig = envPolicyConfig,
}) {
  const { 0: width, 1: height } = aspectRatio
    .split("-")
    .map((el: string) => parseInt(el));
  const [themeType, setThemeType] = useState(theme);
  const [urls, setUrls] = useState<SubwireRooms>();
  interface SubwireRooms {
    [name: string]: {
      roomId: string;
      venmo?: string;
      fingerprint?: string;
      created_at?: number;
    };
  }

  useEffect(() => {
    if (
      window.location.hostname.includes("ngrok") ||
      window.location.hostname.includes("stumble")
    ) {
      setUrls(ngrok_rooms);
    } else {
      getVanityUrls(process.env.VANITY_URL_ENDPOINT).then(vanityUrls => {
        setUrls(vanityUrls);
      });
    }
  }, []);

  useEffect(() => {
    setThemeType(theme);
  }, [theme]);

  return (
    <ErrorBoundary>
      <FingerprintProvider>
        <HMSRoomProvider isHMSStatsOn={FeatureFlags.enableStatsForNerds}>
          <HMSThemeProvider
            themeType={"dark"}
            aspectRatio={getAspectRatio({ width, height })}
            theme={{
              colors: {
                // @ts-expect-error
                brandLight: "#e8c972",
                // @ts-expect-error
                brandDefault: "#9e1f63",
                // @ts-expect-error
                brandDark: "#90752d",
                // @ts-expect-error
                brandDisabled: "#90752d",
              },
              fonts: {
                // @ts-expect-error
                sans: ["$sans", "Inter", "sans-serif"],
              },
            }}
          >
            <AppContextProvider
              roomId={roomId}
              tokenEndpoint={tokenEndpoint}
              policyConfig={policyConfig}
              appDetails={metadata}
              logo={logo || Logo}
            >
              <Box
                css={{
                  bg: "$mainBg",
                  w: "100%",
                  ...(headerPresent === "true"
                    ? { flex: "1 1 0", minHeight: 0 }
                    : { h: "100%" }),
                }}
              >
                {urls && (
                  <AppRoutes
                    getUserToken={getUserToken}
                    appDetails={metadata}
                    urls={urls}
                  />
                )}
              </Box>
            </AppContextProvider>
          </HMSThemeProvider>
        </HMSRoomProvider>
      </FingerprintProvider>
    </ErrorBoundary>
  );
}

const defaultRole = "viewer";
const defaultRoomId = process.env.DEFAULT_ROOM_ID;
const defaultVenmo = "subwireceo";

function AppRoutes({ getUserToken, appDetails, urls }: AppRoutesProps) {
  const localPeerID = useHMSStore(selectLocalPeerID);
  const { roomId } = useParams();

  return (
    <Router>
      <ToastContainer />
      <Notifications />
      <Confetti />
      <AppData appDetails={appDetails} />
      <KeyboardHandler />
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <PreviewScreen
                getUserToken={getUserToken}
                room={urls["subwireceo"]?.roomId}
                role={defaultRole}
                roomName=""
              />
            </Suspense>
          }
        />
        <Route
          path="/love"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <PreviewScreen
                getUserToken={getUserToken}
                room={"66575994e85e6768d0e3fb29"}
                role={"stage"}
                roomName="stumble"
              />
            </Suspense>
          }
        />
        <Route
          path="/subwire-test-bed"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <PreviewScreen
                getUserToken={getUserToken}
                room="6441ba51e46ec319b1eb54d9"
                role="audio_128_kbps"
                roomName="subwire-test-bed"
              />
            </Suspense>
          }
        />
        <Route
          path="/subwire-test-bed/live"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room="6441ba51e46ec319b1eb54d9"
                userRole="audio_128_kbps"
                venmoHandle={defaultVenmo}
                vanityUrl="subwire-test-bed"
              />
            </Suspense>
          }
        />
        <Route
          path="/rtmp"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <HLSView roomId="65413e45031212eae608a7f1" storedMessages={[]} />
            </Suspense>
          }
        />
        <Route
          path={`/${roomName}`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <PreviewScreen
                getUserToken={getUserToken}
                room={urls[roomName]?.roomId || defaultRoomId}
                role={defaultRole}
                roomName={roomName}
              />
            </Suspense>
          }
        />
        <Route
          path={`/${roomName}-backstage`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <PreviewScreen
                getUserToken={getUserToken}
                room={urls[roomName]?.roomId || defaultRoomId}
                role="backstage"
                roomName={roomName}
              />
            </Suspense>
          }
        />
        <Route
          path={`/${roomName}-on-stage`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <PreviewScreen
                getUserToken={getUserToken}
                room={urls[roomName]?.roomId || defaultRoomId}
                role="stage"
                roomName={roomName}
              />
            </Suspense>
          }
        />
        <Route
          path={`/${roomName}/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={urls[roomName]?.roomId || defaultRoomId}
                userRole={defaultRole}
                venmoHandle={urls[roomName]?.venmo || defaultVenmo}
                vanityUrl={roomName}
              />
            </Suspense>
          }
        />
        <Route
          path={`/${roomName}-backstage/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={urls[roomName]?.roomId || defaultRoomId}
                userRole="backstage"
                venmoHandle={urls[roomName]?.venmo || defaultVenmo}
                vanityUrl={roomName}
              />
            </Suspense>
          }
        />
        <Route
          path={`/${roomName}-on-stage/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={urls[roomName]?.roomId || defaultRoomId}
                userRole="stage"
                venmoHandle={urls[roomName]?.venmo || defaultVenmo}
                vanityUrl={roomName}
              />
            </Suspense>
          }
        />
        <Route
          path={`/leave/${roomName}/stage`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <PostLeave vanityUrl={roomName} role="-on-stage" />
            </Suspense>
          }
        />
        <Route
          path={`/leave/${roomName}/backstage`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <PostLeave vanityUrl={roomName} role="backstage" />
            </Suspense>
          }
        />
        <Route
          path={`/leave/${roomName}/viewer`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <PostLeave vanityUrl={roomName} />
            </Suspense>
          }
        />
        <Route
          path="/leave/*"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <PostLeave vanityUrl={roomName} />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <ErrorPage error="Invalid URL!" />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}

export default function App() {
  return (
    <HMSRoomProvider isHMSStatsOn={FeatureFlags.enableStatsForNerds}>
      {" "}
      <EdtechComponent
        themeConfig={{
          aspectRatio: process.env.REACT_APP_TILE_SHAPE,
          theme:
            process.env.REACT_APP_THEME === "light"
              ? ThemeTypes.default
              : ThemeTypes.default,
          color: process.env.REACT_APP_COLOR,
          logo: process.env.REACT_APP_LOGO,
          font: process.env.REACT_APP_FONT,
          headerPresent: process.env.REACT_APP_HEADER_PRESENT,
          metadata: process.env.REACT_APP_DEFAULT_APP_DETAILS, // A stringified object in env
        }}
        getUserToken={defaultGetUserToken}
      />
    </HMSRoomProvider>
  );
}
